<template>
  <div class="feed-body">
    <v-img :src="bg_url" class="bg-img" v-if="bg_url" />
    <div class="feed-contents">
      <weather-location-search
        v-on:click.prevent
        @setWeather="getLocationFromAddress"
        style="width: 100%"
      />
      <div style="width: 100%">
        <weather-current
          :weather="currentWeather"
          class="mt-3"
          :address="address"
        />
      </div>
      <div style="width: 100%; color: white; font-weight: bold;" class="mt-10">
        Daily
      </div>
      <weekly-weather
        :dailyforecast="weeklyForecast"
        :unit="unit"
        style="width: 100%"
      />
      <div style="width: 100%; color: white; font-weight: bold;" class="mt-10">
        Hourly
      </div>
      <hourly-weather
        :hourlyforecast="hourlyForecast"
        :unit="unit"
        style="width: 100%"
        class="mb-10"
      />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import HourlyWeather from "./HourlyWeather.vue";
import WeatherCurrent from "./WeatherCurrent.vue";
import WeatherLocationSearch from "./WeatherLocationSearch.vue";
import WeeklyWeather from "./WeeklyWeather.vue";
export default {
  components: {
    WeatherLocationSearch,
    WeatherCurrent,
    WeeklyWeather,
    HourlyWeather,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      center: { lat: 42.2788668, lng: -71.4206997 },
      currentWeather: null,
      weeklyForecast: null,
      hourlyForecast: null,
      unit: "imperial", //"metric"
      address: "",
      selected: null,
      self: true,
    };
  },
  methods: {
    ...mapActions({
      getLocation: "need/getLocationWithAddress",
      getCurrentWeather: "weather/getCurrentWeather",
      getWeeklyWeather: "weather/getWeeklyWeather",
      setWeather: "auth/setWeather",
      setUnit: "auth/setUnit",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    getProfileLocation() {
      this.address = `${this.profile.address}${
        this.profile.address2 ? " " + this.profile.address2 : ""
      }, ${this.profile.city}, ${this.profile.state} ${this.profile.zip}`;
      this.getLocation(this.address).then((res) => {
        this.center = res.geometry.location;
        this.address = res.formatted_address.replace(", USA", "");
        this.self = true;
        this.getWeather();
      });
    },
    getLocationFromAddress(location) {
      this.getLocation(location).then((res) => {
        this.center = res.geometry.location;
        this.address = res.formatted_address.replace(", USA", "");
        this.self = false;
        this.getWeather();
      });
    },
    getWeather() {
      this.getCurrentWeather({ unit: this.unit, ...this.center })
        .then((res) => {
          this.currentWeather = res;
          if (this.self) {
            this.setWeather(res);
            this.setUnit(this.unit);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.getWeeklyWeather({ unit: this.unit, ...this.center })
        .then((res) => {
          this.weeklyForecast =
            res.daily.length == 8 ? res.daily.slice(1) : res.daily;
          this.hourlyForecast = res.hourly;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSelectedCard(w) {
      this.selected = w;
    },
    switchUnit() {
      if (this.unit == "imperial") this.unit = "metric";
      else this.unit = "imperial";
      this.getWeather();
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
      profile: "auth/getProfile",
      needs: "need/getAllNeeds",
      systemUnit: "usersetting/getUnit",
    }),
    bg_url() {
      if (
        !this.currentWeather ||
        !this.currentWeather.weather ||
        !this.currentWeather.weather.length
      )
        return "";
      var main = this.currentWeather.weather[0]["main"];
      if (main) {
        main = main.toLowerCase();
        let icon = this.currentWeather.weather[0]["icon"];
        if (
          icon.substr(icon.length - 1) == "n" &&
          (main == "clear" || main == "clouds")
        ) {
          return require(`@/assets/weather/${main.toLowerCase()}_night.jpg`);
        }

        return require(`@/assets/weather/${main.toLowerCase()}.jpg`);
      } else {
        return "";
      }
    },
  },
  watch: {
    profile(newValue) {
      if (newValue.address) {
        this.getProfileLocation();
      }
    },
  },
  mounted() {
    if (this.profile.address) {
      this.getProfileLocation();
    }
    this.unit = this.systemUnit;
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.feed-contents {
  width: 100%;
  height: 100%;
  /* max-width: 650px; */
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  z-index: 1;
}
.bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
</style>
