<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="d-flex flex-row"
      >
        <weather-contents-section />
        <volunteer-right-section
          class="hidden-sm-and-down"
          v-if="profileType == 'Agency'"
        />
        <agency-right-section
          class="hidden-sm-and-down"
          v-else-if="profileType == 'Volunteer'"
        />
        <admin-right-section
          class="hidden-sm-and-down"
          v-else-if="profileType == 'Admin'"
        />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapGetters } from "vuex";
import AdminRightSection from "../admin/AdminRightSection.vue";
import AgencyRightSection from "../agency/AgencyRightSection.vue";
import VolunteerRightSection from "../volunteer/VolunteerRightSection.vue";
import WeatherContentsSection from "./WeatherContentsSection.vue";
export default {
  components: {
    VolunteerRightSection,
    AgencyRightSection,
    WeatherContentsSection,
    AdminRightSection,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: [],
    };
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
    }),
  },
};
</script>
<style scoped></style>
