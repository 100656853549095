<template>
  <div v-if="hourlyforecast">
    <div
      class="d-flex flex-row"
      style="overflow-x: auto; width: 100%"
      v-dragscroll.x
    >
      <div
        v-for="(fc, index) in hourlyforecast"
        :key="index"
        style="padding: 10px;"
      >
        <hourly-weather-card :weather="fc" />
      </div>
    </div>
  </div>
</template>
<script>
import HourlyWeatherCard from "./HourlyWeatherCard.vue";
export default {
  components: { HourlyWeatherCard },
  name: "HourlyWeather",
  props: {
    hourlyforecast: {
      type: [Object, Array],
    },
    onSelected: Function,
    unit: String,
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  height: 10px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #febb349a;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}
</style>
